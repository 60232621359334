@use "../config/" as *;

.waf-player {
    @extend %p-0;
    .waf-head {
        @extend %relative;
        @extend %pb-3;
        @extend %primary-900-bg;
        &.active {
            padding-bottom: 21.5rem;
            .player-info-list,
            .player-stats-list,
            .player-meta-list,
            .player-name {
                z-index: var(--z-player-profile-hide);
            }
            .player-bio-wrap {
                justify-content: flex-end;
                height: calc(100% - 40.2rem);
                @include position(null, null, 1rem);
            }
            .content {
                display: unset;
                overflow: visible;
            }
        }
    }
    .country-flag,
    .dropdown-group,
    .tab-list,
    .player-tracker-view,
    .waf-footer {
        @extend %d-none;
    }
    .player {
        &-thumbnail {
            order: -1;
            width: 27rem;
            isolation: isolate;
            aspect-ratio: 270 / 373;
            @extend %relative;
            @extend %mx-auto;
            &:before {
                content: '';
                width: var(--window-inner-width);
                bottom: 0;
                z-index: var(--z-player-profile-bg);
                @include background(null, "pattern/player-profile-bg.png", top / cover no-repeat);
                @extend %h-100;
                @extend %pos-x-center;
            }
            img {
                @extend %h-100;
            }
        }
        &-info-wrap {
            flex-wrap: wrap;
            @extend %flex;
        }
        &-info-list {
            border-radius: 0 0 var(--full-radius) var(--full-radius);
            @extend %gap-8;
            @extend %flex-c-c;
            @extend %neutral-900-bg-2;
            @extend %mx-2;
            @extend %w-100;
            @extend %py-4;
            @extend %px-3;
            @extend %pb-3;
        }
        &-name {
            border-radius: var(--full-radius) var(--full-radius) 0 0;
            @extend %relative;
            @extend %gap-1;
            @extend %flex-c-n;
            @extend %mt-4;
            @extend %pt-3;
            @extend %px-3;
            @extend %neutral-900-bg-2;
            @extend %mx-2;
            @extend %w-100;
            &::after {
                content: '';
                height: .1rem;
                width: calc(100% - var(--space-6));
                @extend %primary-400-bg;
                @include position(null, null, var(--space-2-neg), var(--space-3));
            }
        }
    }
    .player-info-wrap {
        .country-name,
        .role,
        .name {
            @extend %capitalize;
        }
        .country-name,
        .role {
            @extend %neutral-50;
            @extend %font-16-pm;
        }
        .name {
            @extend %tertiary-600;
            @extend %font-20-pb;
        }
    }
    .player-stats {
        &-list {
            @extend %my-4;
            @extend %gap-2;
            @extend %flex;
            @extend %w-100;
            @extend %mx-2;
        }
        &-item {
            flex: 1;
            height: 10rem;
            @include flex-config(flex, column-reverse, center, center);
            @extend %relative;
            @extend %gap-4;
            @extend %full-radius;
            @extend %neutral-900-bg-2;
            &::after {
                content: '';
                height: .1rem;
                width: calc(100% - var(--space-6));
                @extend %primary-400-bg;
                @extend %pos-center;
            }
        }
        &-count {
            @extend %tertiary-600;
            @extend %font-22-pb;
        }
        &-title {
            @extend %capitalize;
            @extend %font-16-pm;
            @extend %neutral-50;
        }
    }
    .player-meta {
        &-list {
            flex-wrap: wrap;
            @extend %mx-2;
            @extend %gap-2;
            @extend %flex;
            @extend %w-100;
        }
        &-item {
            width: calc(50% - var(--space-1));
            @extend %gap-2;
            @extend %p-3;
            @extend %flex-column-c-c;
            @extend %full-radius;
            @extend %neutral-900-bg-2;
        }
        &-title,
        &-value {
            @extend %neutral-50;
        }
        &-title {
            @extend %capitalize;
            @extend %font-12-pm;
        }
        &-value {
            @extend %text-center;
            @extend %font-12-pb;
        }
    }
    .player-bio {
        @extend %y-auto;
        &-title {
            @extend %mb-1;
            @extend %capitalize;
            @extend %neutral-50;
            @extend %font-12-pb;
        }
        &-wrap {
            @include flex-config(flex, column-reverse);
            @extend %p-3;
            @extend %full-radius;
            @extend %mt-3;
            @extend %mx-2;
            @extend %gap-2;
            @extend %neutral-900-bg-2;
            .toggle-bio {
                @extend %ml-auto;
                @extend %flex-fe-n;
                @extend %text-right;
                @extend %relative;
                .btn-text {
                    @extend %font-0;
                }
                &::after {
                    content: 'Read More';
                    transition: background-color .3s linear;
                    @extend %half-radius;
                    @extend %p-1;
                    @extend %tertiary-600;
                    @extend %font-14-pb;
                    @extend %uppercase;
                }
                &[aria-expanded="true"] {
                    &::after {
                        content: 'Read Less';
                    }
                }
            }
            .content {
                @include truncate(6, 12);
                @extend %neutral-50;
            }
        }
    }
    .captain,
    .overseas {
        @extend %relative;
        &:before,
        &::after {
            z-index: var(--z-player-profile-overseas);
            width: 2.8rem;
            height: 2.8rem;
            @include position(15rem, null, null, var(--space-2));
            @extend %circle-radius;
            @extend %flex-c-c;
        }
    }
    .captain {
        &::after {
            content: 'C';
            font-weight: 700;
            @extend %neutral-50;
            @extend %accent-900-bg;
        }
    }
    .overseas {
        &:before {
            @include icon(overseas);
            @extend %neutral-800;
            @extend %neutral-50-bg;
        }
        &.captain {
            &::after {
                top: 11rem;
            }
        }
    }
    .waf-body {
        @extend %px-2;
        @extend %relative;
        &:before {
            content: '';
            z-index: var(--z-white-pattern-bg);
            width: var(--window-inner-width);
            @include background(null, "pattern/white-bg.png", top / cover no-repeat);
            @extend %h-100;
            @extend %pos-tl;
        }
    }
    .stats-head {
        width: calc(100% - 5rem);
        @extend %py-4;
        .title {
            @extend %capitalize;
            @extend %neutral-800;
            @extend %font-18-pb;
        }
    }
    .stats-table {
        border-radius: var(--full-radius);
        border: 0;
        @extend %mb-4;
        @extend %flex;
    }
    .table {
        &-head {
            @extend %secondary-800-bg;
            .text {
                @extend %uppercase;
                @extend %font-12-pb;
            }
        }
        &-left {
            @extend %w-50;
            .text {
                @extend %font-12-pm;
            }
            .table-head {
                border-top-left-radius: var(--full-radius);
            }
            .table-data {
                @include border(1, neutral-300, 10, right);
            }
            .table-body {
                .table-row {
                    &:last-child {
                        .table-data {
                            border-bottom-left-radius: var(--full-radius);
                        }
                    }
                }
            }
        }
        &-right {
            @extend %w-50;
            .text {
                @extend %font-12-pb;
            }
            .table-head {
                border-top-right-radius: var(--full-radius);
            }
            .table-body {
                .table-row {
                    &:last-child {
                        .table-data {
                            border-bottom-right-radius: var(--full-radius);
                        }
                    }
                }
            }
        }
        &-body {
            .table-data {
                height: 3.6rem;
            }
            .table-row {
                &:nth-child(even) {
                    .table-data {
                        @extend %neutral-50-bg;
                    }
                }
                &:nth-child(odd) {
                    .table-data {
                        @extend %pure-white-900-bg;
                    }
                }
            }
            .text {
                @extend %uppercase;
                @extend %neutral-800;
            }
        }
    }
    .filter-section {
        top: var(--space-2);
    }
}
@include mq(col-md) {
    .waf-player {
        .layout-wrapper {
            max-width: 100%;
        }
        .player-thumbnail {
            &::before {
                background-position: bottom;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-player {
        .filter-section {
            display: none;
        }
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .waf-head {
            padding-bottom: 0;
            position: relative;
            height: calc(var(--window-inner-height) - var(--header-height));
            overflow-y: clip;
            &::before {
                content: '';
                width: var(--window-inner-width);
                height: calc(var(--window-inner-height) - var(--header-height));
                @include position(0, null, null, calc(var(--container-white-space)*-1));
                @include background(null, "pattern/player-profile-bg-web.png", top / cover no-repeat);
            }
            &::after {
                content: '';
                background: linear-gradient(180deg, clr(secondary-900, 6) 68.48%, clr(secondary-900, 0) 100%);
                height: calc(var(--window-inner-height) - var(--header-height));
                width: 50%;
                backdrop-filter: blur(10px);
                @include position(0, 0, null, null);
            }
            &.active {
                .player-bio-wrap {
                    height: calc(100% - var(--space-8));
                    @include position(var(--space-2), 0, unset);
                }
            }
        }
        .overseas:before,
        .overseas::after {
            left: 45%;
        }
        .player {
            &-thumbnail {
                width: 50%;
                height: 100%;
                aspect-ratio: unset;
                @include position(null, null, 0, 0);
                &:before {
                    display: none;
                }
            }
            &-image {
                width: auto;
                margin-inline: auto;
            }
            &-info-wrap {
                position: unset;
                z-index: var(--z-player-profile-text);
                padding-inline: 0;
                margin-right: var(--space-4);
                @include flex-config(flex, column, null, flex-end);
                .name {
                    font-size: 2.4rem;
                }
            }
            &-stats-list {
                margin-block: var(--space-3);
            }
            &-name,
            &-info-list,
            &-stats-list,
            &-meta-list {
                margin-inline: 0;
                width: calc(50% - var(--space-6));
                z-index: var(--z-player-profile-text);
            }
            &-bio-wrap {
                z-index: var(--z-player-profile-text);
                position: relative;
                width: calc(50% - 3.8rem);
                margin-inline: auto var(--space-4);
                @include flex-config(flex, null, flex-end);
                .content {
                    @include truncate(3, 14);
                }
            }
            &-meta-item {
                flex-direction: row;
            }
        }
        .stats-head {
            padding-block: var(--space-8) var(--space-5);
            .title {
                font-size: 3.2rem;
            }
        }
        .waf-body {
            padding-inline: 0;
            &::before {
                left: calc(var(--container-white-space)*-1);
            }
        }
        .table {
            &-left {
                width: 30rem;
                .table-body {
                    .text {
                        font-size: 1.4rem;
                    }
                    .table-row {
                        &:last-child {
                            .table-data {
                                background: var(--tertiary-600);
                                .text {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
            &-right {
                width: calc(100% - 30rem);
                .table-body {
                    .text {
                        font-weight: 500;
                    }
                    .table-row {
                        &:last-child {
                            isolation: isolate;
                            position: relative;
                            .table-data {
                                background: transparent;
                                border-radius: 0;
                                .text {
                                    font-weight: 700;
                                }
                                &:last-child {
                                    border-bottom-right-radius: var(--full-radius);
                                }
                            }
                            &::before {
                                content: '';
                                height: 4rem;
                                z-index: var(--z-white-pattern-bg);
                                background: linear-gradient(90deg, var(--tertiary-600) 0%, clr(tertiary-600, 2) 88.49%, clr(tertiary-600, 0) 98.5%);
                                border-bottom-right-radius: var(--full-radius);
                                width: 100%;
                                @include position(0, null, null, 0);
                            }
                        }
                    }
                }
                .table-data {
                    &:not(:last-child) {
                        @include border(1, neutral-300, 10, right);
                    }
                }
            }
            &-body {
                .table-data {
                    height: 4rem;
                }
            }
        }
    }
}