@use "../config/" as *;
.webview {
    @extend %secondary-1200-bg;

    %no-data {
        @extend %d-inline-block;
        @extend %primary-50-bg;
        @extend %font-16-qb;
        @extend %uppercase;
        @extend %text-center;
        @extend %py-3;
        @extend %my-2;
        @extend %w-100;
    }
    .waf-player .stats-head .title,
    .waf-squad .waf-head .title {
        border-block: .1rem solid var(--accent-100);
        @extend %pure-white-900;
        @extend %py-2;
        @extend %d-inline-block;
        @extend %uppercase;
        @extend %font-20-qb;
    }
    .waf-player,
    .waf-squad {
        .player-stats {
            &-title {
                @extend %font-14-qr;
                @extend %primary-1200;
                @extend %uppercase;
            }
            &-item {
                justify-content: unset;
                border-radius: 0;
                @extend %py-3;
                @extend %neutral-900-bg-2;
            }
            &-count {
                @extend %font-28-qb;
                @extend %accent-100;
            }
        }
    }
    .waf-player {
        .captain {
            &::after {
                top: 5rem;
                @extend %font-14-qb;
            }
        }
        .overseas {
            &::before {
                top: 5rem;
            }
        }
        .captain.overseas {
            &::before {
                top: 9rem;
            }
        }
        .waf-head,
        .waf-body {
            @extend %secondary-1200-bg;
        }
        .waf-body {
            @extend %flex-sb-c;
            @extend %flex-wrap;
            &::before {
                content: unset;
            }
        }
        .player-meta-title,
        .player-meta-value,
        .player-bio-wrap .player-bio-title {
            @extend %uppercase;
        }
        .player {
            &-thumbnail {
                &::before {
                    @include bgImg("pattern/player-profile-webview.png");
                }
            }
            &-info-wrap {
                .name {
                    @extend %uppercase;
                    @extend %font-24-qb;
                }
                .first-name {
                    font-weight: 400;
                }
                .role,
                .country-name {
                    @extend %font-16-qr;
                    @extend %uppercase;
                    @extend %primary-1200;
                }
            }
            &-bio-wrap {
                background: linear-gradient(to top, var(--secondary-800) 0%, var(--primary-700) 100%);
                @extend %gap-6;
                .player-bio-title {
                    @extend %font-16-qb;
                    @extend %pure-white-900;
                }
                .content {
                    line-height: 2.8rem;
                    @extend %font-16-tr;
                    @extend %primary-1200;
                    p {
                        font: inherit;
                    }
                    &.no-data{
                        height: unset;
                        @extend %no-data;
                        @extend %neutral-800;
                    }
                }
                .toggle-bio {
                    @extend %accent-100-bg;
                    &::after {
                        content: "View More";
                        @extend %pure-white-900;
                        @extend %font-12-qm;
                        @extend %px-2;
                    }
                    &[aria-expanded=true]::after {
                        content: "View Less";
                    }
                }
            }
            &-meta {
                &-title {
                    @extend %font-12-qr;
                    @extend %primary-1200;
                }
                &-value {
                    @extend %font-14-qb;
                }
                &-item {
                    justify-content: unset;
                }
            }
            &-info-wrap .name {
                @extend %accent-100;
            }
            &-name,
            &-info-list,
            &-meta-item,
            &-bio-wrap {
                border-radius: 0;
            }
            &-name,
            &-info-list,
            &-meta-item {
                @extend %neutral-900-bg-2;
            }
            &-name,
            &-stats,
            &-role {
                &::after {
                    @extend %primary-1000-bg;
                }
            }
            &-role {
                @extend %relative;
                &::after {
                    content: "";
                    width: .05rem;
                    @include position-combo("y-center", -2rem);
                    @extend %h-100;
                }
            }
        }
        .filter-section {
            position: unset;
            flex-basis: 10%;
            .btn-more {
                border-radius: 0;
                @extend %secondary-500-bg;
                &::before {
                    content: "\e809";
                    @extend %font-23;
                }
            }
            .btn-cancel {
                border: none;
                &::after {
                    @extend %font-14;
                }
            }
            .more-filters {
                @include background(clr(secondary-1200), "pattern/filter-webview-bg.png", top/cover no-repeat);
                .head {
                    @extend %px-4;
                    .title {
                        @extend %uppercase;
                        @extend %font-16-qb;
                    }
                }
                .body {
                    height: calc(100% - 11.8rem);
                    @extend %flex-column;
                    @extend %gap-2;
                    @extend %pure-white-900;
                }
                .footer {
                    @extend %pure-white-900-bg;
                    @extend %my-0;
                    @extend %p-3;
                }
                .btn {
                    &-applyfilter,
                    &-resetfilter {
                        border-radius: 0;
                        .btn-text {
                            @extend %font-16-qm;
                        }
                    }
                    &-applyfilter {
                        border-color: clr(accent-100);
                        @extend %accent-100-bg;
                        .btn-text {
                            @extend %pure-white-900;
                        }
                    }
                    &-resetfilter {
                        border-color: clr(neutral-300);
                    }
                }
            }
            .waf-select-box {
                background-color: clr(primary-50, .8);
                .selected-title {
                    height: auto;
                    .title,
                    .sub-title {
                        @extend %font-12-qb;
                        @extend %uppercase;
                    }
                    .title {
                        width: max-content;
                        max-width: 100%;
                        @extend %p-1;
                        @extend %secondary-500-bg;
                        @extend %text-center;
                        @extend %pure-white-900;
                    }
                    &::after {
                        @extend %accent-100;
                    }
                }
                .select-list {
                    .list-item {
                        @extend %pure-white-900;
                        @extend %px-0;
                        @extend %font-12-qr;
                        @extend %uppercase;
                        a {
                            @extend %font-12-qr;
                        }
                        &:not(:last-child)::before {
                            @extend %pure-white-900-bg-1;
                        }
                    }
                }
                .select-box-wrap {
                    position: unset;
                    background: unset;
                    @extend %px-0;
                    @extend %mt-2;
                }
                &.active::after {
                    content: unset;
                }
                &::before {
                    content: unset;
                }
            }
        }
        .stats {
            &-head {
                flex-basis: 80%;
                order: -1;
            }
            &-body {
                @extend %w-100;
            }
            &-table {
                border-radius: 0;
                border: .1rem solid clr(primary-1000);
                border-bottom: 0;
                .table-data {
                    height: 4rem;
                    border-bottom: .1rem solid clr(primary-1000);
                }
                .table-head .text {
                    @extend %font-12-qb;
                }
                .table-left,
                .table-right {
                    .table-head {
                        border-radius: 0;
                    }
                    .table-body {
                        .text {
                            @extend %font-12-qr;
                            @extend %pure-white-900;
                        }
                        .table-row {
                            &:last-child .table-data {
                                border-radius: 0;
                            }
                            &:nth-child(odd),
                            &:nth-child(even) {
                                .table-data {
                                    background: unset;
                                }
                            }
                        }
                    }
                }
                .table-left .table-data {
                    border-color: clr(primary-1000);
                }
                .table-right .table-body .text {
                    font-weight: 700;
                }
            }
        }
    }
    .table-left,
    .table-right {
        background: unset;
    }
    .waf-squad {
        @extend %secondary-1200-bg;
        .waf-head {
            .head {
                &-wrap {
                    @extend %gap-1;
                }
                &-tab {
                    flex-shrink: 0;
                    li {
                        border: none;
                    }
                    a {
                        border-radius: 0;
                        width: unset;
                        @extend %p-1-2;
                        @extend %accent-100-bg;
                        @extend %pure-white-900;
                        @extend %font-12-qr;
                        &:hover {
                            @extend %accent-100-bg;
                        }
                    }
                }
            }
        }
        .squad-item {
            @extend %pt-0;
            &.captain .player-wrap::before {
                @extend %font-14-qb;
            }
            &.captain.overseas .player-wrap {
                &::before {
                    left: 2rem;
                }
                &::after {
                    top: 5.5rem;
                }
            }
            .toggle-stats[aria-expanded=true] ~ .player-content {
                background: clr(primary-900, 9.4);
            }
        }
        .player-wrap,
        .player-content,
        .squad-item {
            border-radius: 0;
        }
        .player {
            &-thumbnail {
                @extend %mt-0;
            }
            &-content {
                bottom: -19rem;
                background-color: clr(primary-900, 9.4);
            }
            &-wrap {
                @include bgImg("pattern/player-profile-webview.png");
            }
            &-name .name {
                @extend %accent-100;
                @extend %font-18-qb;
                @extend %uppercase;
            }
            &-stats-item,
            &-name {
                &::after {
                    @extend %primary-1000-bg;
                }
            }
            &-role .role {
                @extend %primary-50;
                @extend %font-14-tm;
            }
            &-country::before {
                @extend %primary-500-bg;
            }
        }
        .card-action .btn-site {
            border-radius: 0;
            border-color: clr(accent-100);
            @extend %accent-100;
            @extend %font-14-qm;
            &:hover {
                @extend %accent-100-bg;
                @extend %pure-white-900;
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            @extend %d-none;
        }
    }
    .no-data{
        @extend %no-data;
    }
    
}