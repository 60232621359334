@use "./config/" as *;
@forward "./main-scss/";
@forward "./squad/player-profile";
@forward "./squad/squad-listing";
@forward "./swiper/swiper-bundle";
@forward "./squad/webview";
.waf-squad {
    @extend %neutral-100-bg;
    .tab-section {
        @extend %d-none;
    }
    .head-tab a {
        width: 8rem;
    }
    .waf-body {
        margin-top: var(--space-4);
    }
    .player {
        &-thumbnail {
            height: 30rem;
            @extend %transition;
        }
        &-content {
            border-radius: var(--full-radius);
        }
    }
    .squad {
        &-wrapper {
            flex-wrap: nowrap;
            overflow-x: unset;
            gap: 0;
        }
        &-listing {
            @extend %pb-4;
        }
        &-item {
            &:has([aria-expanded=true]) {
                .player-thumbnail {
                    opacity: 0;
                }
            }
        }
    }
    .swiper-button {
        bottom: var(--space-0);
        &-next {
            right: var(--space-2);
        }
        &-prev {
            right: var(--space-10);
        }
    }
}
@include mq(col-md) {
    .waf-squad {
        .squad-item {
            &:hover {
                .player-thumbnail {
                    opacity: 0;
                }
            }
        }
    }
}